/*
 *
 *   GULL - React Bootstrap Admin Template
 *
 *
*/

@import '~react-checkbox-tree/src/scss/react-checkbox-tree.scss';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~react-datetime/css/react-datetime.css';
@import '~rc-slider/assets/index.css';
@import '~rc-tooltip/assets/bootstrap.css';
@import '~react-quill/dist/quill.bubble.css';
@import '~react-quill/dist/quill.snow.css';
@import '~react-quill/dist/quill.core.css';
@import '~react-vis/dist/style';
@import '~ladda/dist/ladda.min.css';
@import '~react-notifications/lib/notifications.css';
@import '~sweetalert2/src/sweetalert2.scss';
@import '~react-image-crop/dist/ReactCrop.css';
@import '~react-datepicker/dist/react-datepicker.css';
@import '../../../node_modules/@fullcalendar/timegrid/main.css';
@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import '~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '~react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
@import '../iconsmind/iconsmind.css';
@import 'variables';
// @import 'themes/lite-blue';
@import 'themes/lite-purple.scss';
@import '~bootstrap/scss/bootstrap.scss';
@import 'bootstrap-rtl.scss';
@import 'globals/globals';
@import 'custorm.css';
